import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Subcategoria } from "src/app/models/subcategoria.model";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class SubcategoriaService {
  constructor(public api: ApiService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    idCategoria: string = "",
    filtro: string = "%"
  ): Observable<SubcategoriasApi> {
    const params = {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
      idCategoria: idCategoria,
    };
    return this.api.get("/subcategoria/buscar", params);
  }

  getById(id): Observable<any> {
    return this.api.get(`/subcategoria/buscar`, { id: id });
  }

  post(subcategoria: Subcategoria): Observable<any> {
    return this.api.post("/subcategoria/inserir", subcategoria);
  }

  patch(subcategoria: Subcategoria): Observable<any> {
    return this.api.post("/subcategoria/alterar", subcategoria);
  }

  delete(subcategoria: Subcategoria): Observable<any> {
    return this.api.post("/subcategoria/deletar", subcategoria);
  }

  deleteSelected(subcategorias: Subcategoria[]): Observable<any> {
    return this.api.post("/subcategoria/deletarLista", subcategorias);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: any, v2: any): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface SubcategoriasApi {
  subcategorias: Subcategoria[];
  numeroPaginas: number;
}
