import { Categoria } from "./categoria.model";

export class Subcategoria {
  id: number = null;
  categoria: Categoria;
  nome: string = "";
  imagem: string = "";
  slug: string = "";
  imagemInterna: string = "";
  order: number = -99;
  mostraTitulo: string = "S";
  situacao: string = "A";
  [x: string]: any;
}
