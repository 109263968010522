import { HttpProgressEvent } from "@angular/common/http";
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { GlobalService } from "src/app/services/global.service";
import { HelperService } from "src/app/services/helper.service";
import { LoadingService } from "src/app/services/loading.service";
import { UploadService } from "src/app/services/upload.service";

interface UploadProps {
  imagePath: string;
  fileName: string;
}

@Component({
  selector: "app-upload-dialog",
  templateUrl: "./upload-dialog.component.html",
  styleUrls: ["./upload-dialog.component.scss"],
})
export class UploadDialogComponent implements OnInit {
  file: File;
  progress: number = 0;

  imageUri: string = "";
  imagePath: string = "";
  fileName: string = "imagem";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UploadProps,
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    public _upload: UploadService,
    public global: GlobalService,
    public loadingService: LoadingService,
    public helper: HelperService
  ) {}

  ngOnInit(): void {
    this.imageUri = this.global.imageUrl + this.data.imagePath;
    this.imagePath = this.data.imagePath;
    this.fileName = this.data.fileName;
  }

  dismiss(status) {
    this.dialogRef.close(status ? this.imagePath : "");
  }

  @ViewChild("fileInput", { static: true }) fileInput: ElementRef;
  onFileSelected(event) {
    this.file = event.target.files.item(0);
    this.loadingService.present("0%");
    this.submit(this.file)
      .then((res: any) => {
        this.imageUri = this.global.imageUrl + res.body;
        this.imagePath = res.body;
        this.loadingService.dismiss();
      })
      .catch(() => {
        this.progress = 0;
        this.fileInput.nativeElement.value = "";
        this.loadingService.dismiss();
      });
  }

  submit(file) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this._upload.upload(file, "image").subscribe(
        (event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        },
        (err) => reject(err)
      );
    });
  }

  removeImage() {
    this.imagePath = "";
    this.imageUri = "";
  }
}
