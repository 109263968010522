// default
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { GlobalService } from "./../../services/global.service";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { merge, of as observableOf, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { Subcategoria } from "src/app/models/subcategoria.model";
import {
  SubcategoriasApi,
  SubcategoriaService,
} from "src/app/services/class/subcategoria.service";

@Component({
  selector: "app-subcategorias",
  templateUrl: "./subcategorias.component.html",
  styleUrls: ["./subcategorias.component.scss"],
})
export class SubcategoriasComponent implements OnInit {
  displayedColumns: string[] = ["select", "imagem", "1", "2", "3", "actions"];
  // imagem, nome, situacao
  data: Subcategoria[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  selection = new SelectionModel<Subcategoria>(true, []);

  constructor(
    public global: GlobalService,
    public subcategoriaService: SubcategoriaService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.buscar();
  }

  buscar() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.subcategoriaService.get(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction.toLocaleUpperCase(),
            this.search
          );
        }),
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.subcategorias;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.data = data));
  }

  deletarSubcategoria(subcategoria: Subcategoria) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir categoria",
        description:
          "Você realmente quer excluir essa categoria? Esse processo não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Excluindo subcategoria");
        this.subcategoriaService.delete(subcategoria).subscribe(
          (res: any) => {
            this.helper.openSnackBar("Subcategoria removida com sucesso.");
            this.loadingService.dismiss();
            this.buscar();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  deletarSubcategorias() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir subcategorias",
        description:
          "Você realmente quer excluir essas categorias? Esse processo não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.subcategoriaService
          .deleteSelected(this.selection.selected)
          .subscribe(
            (res: any) => {
              this.loadingService.present("Excluindo categorias");
              this.helper.openSnackBar("Subcategorias removidos com sucesso.");
              this.loadingService.dismiss();
              this.buscar();
            },
            (e) => this.loadingService.dismiss()
          );
      }
    });
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.subcategoriaService
        .get(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction.toLocaleUpperCase(),
          e.toLocaleLowerCase()
        )
        .subscribe((res: SubcategoriasApi) => {
          this.data =
            this.paginator.pageIndex == 0
              ? res.subcategorias
              : this.data.concat(res.subcategorias);
        });
    } else {
      this.buscar();
    }
  }

  clearFilter() {
    this.search = "";
    this.buscar();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: Subcategoria): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }
}
