import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatSlideToggleChange } from "@angular/material";
import { Router } from "@angular/router";
import { Categoria } from "src/app/models/categoria.model";
import { Subcategoria } from "src/app/models/subcategoria.model";
import { CategoriaService } from "src/app/services/class/categoria.service";
import { SubcategoriaService } from "src/app/services/class/subcategoria.service";
import { HelperService } from "src/app/services/helper.service";
import { LoadingService } from "src/app/services/loading.service";

@Component({
  selector: "app-subcategorias-criar",
  templateUrl: "./subcategorias-criar.component.html",
  styleUrls: ["./subcategorias-criar.component.scss"],
})
export class SubcategoriasCriarComponent implements OnInit {
  data: Subcategoria = new Subcategoria();
  searchOptions: string = "";
  categorias: Categoria[] = [];

  constructor(
    public categoriaService: CategoriaService,
    public subcategoriaService: SubcategoriaService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) {}

  ngOnInit() {
    this.buscarCategorias();
  }

  ngOnDestroy() {}

  submit(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    this.loadingService.present("Inserindo subcategoria...");
    this.data.slug = this.helper.convertToSlug(this.data.nome);

    this.subcategoriaService.post(this.data).subscribe(
      (res: any) => {
        this.router.navigate(["/subcategorias"]).then(() => {
          this.helper.openSnackBar("Subcategoria inserida com sucesso.");
          this.loadingService.dismiss();
        });
      },
      (e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      }
    );
  }

  buscarCategorias() {
    this.categoriaService
      .get(-99, -99, "1", "asc")
      .subscribe((res) => (this.categorias = res.categorias));
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.situacao = "A") : (this.data.situacao = "I");
  }

  onMostraTituloChange(event: MatSlideToggleChange) {
    event.checked
      ? (this.data.mostraTitulo = "S")
      : (this.data.mostraTitulo = "N");
  }
}
