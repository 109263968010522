import { Categoria } from "src/app/models/categoria.model";
import { Galeria } from "./galeria.model";
import { ProdutoSubcategoria } from "./produto-subcategoria.model";
import { Usuario } from "./usuario.model";

// (M-Masculino, F-Feminino, K-Kids, U-Unisex)
export type ProdutoGenero = "M" | "F" | "K" | "U";

export class Produto {
  id: number;
  imagem: string = "";
  nome: string = "";
  link: string = "";
  marca: string = "";
  codigoInterno: string = "";
  modelo: string = "";
  valor: number;
  valorAntigo: number;
  unidadeMedida: string = "";
  descricaoCompleta: string = "";
  situacao: string = "A";
  formaPagamento: string = "";
  categoria: Categoria;
  subcategorias: ProdutoSubcategoria[] = [];
  slug: string = "";
  imagens: Galeria[] = [];
  dataHoraCriacao: Date = new Date();
  usuario: Usuario = new Usuario();
  genero: ProdutoGenero;
  [x: string]: any;

  constructor(obj?) {
    Object.assign(this);
  }
}
