import { Fornecedor } from 'src/app/models/fornecedor.model';
import { FornecedorService } from 'src/app/services/class/fornecedor.service';
// Default
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpProgressEvent } from '@angular/common/http';
import { MatSlideToggleChange } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from './../../services/loading.service';
import { HelperService } from './../../services/helper.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fornecedores-editar',
  templateUrl: './fornecedores-editar.component.html',
  styleUrls: ['./fornecedores-editar.component.scss']
})
export class FornecedoresEditarComponent implements OnInit {

  fornecedor: Fornecedor = new Fornecedor();

  buscarFornecedorSubscription: Subscription;

  file: File;
  progress: number = 0;

  constructor(
    public fornecedorService: FornecedorService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.params.subscribe(param => this.buscarId(param.id))
  }

  ngOnDestroy() {
    this.buscarFornecedorSubscription.unsubscribe();
  }

  buscarId(id: number) {
    this.buscarFornecedorSubscription = this.fornecedorService.getById(id)
      .subscribe((res: Fornecedor) => this.fornecedor = res);
  }

  alterarFornecedor(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar('Preencha os campos requiridos, por favor!');
      return;
    }

    this.loadingService.present('Alterando fornecedor...');

    this.fornecedorService.patch(this.fornecedor)
      .subscribe((res: any) => {
        this.submitImages(this.file, `/fornecedor/imagem?id=${this.fornecedor.id}`);
        this.router.navigate(['/fornecedores']).then(() => {
          this.helper.openSnackBar('Fornecedor alterado com sucesso.');
          this.loadingService.dismiss();
        })
      }, e => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss()
      });

  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? this.fornecedor.situacao = 'A' : this.fornecedor.situacao = 'I';
  }

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  onFileSelected(files) {
    this.file = files.item(0);
    this.loadingService.present('0%');
    this.submitImages(this.file, '/fornecedor/imagem?id=-99').then((res: any) => {
      this.fornecedor.imagem = res.body;
      this.loadingService.dismiss();
    }).catch(() => {
      this.progress = 0;
      this.fileInput.nativeElement.value = '';
      this.loadingService.dismiss();
    });
  }

  submitImages(file: File, url: string) {
    if (!file) {
      return;
    }
    return new Promise((resolve, reject) => {
      this.fornecedorService.postFile(file, url, 'image')
        .subscribe((event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
            this.loadingService.title = `${this.progress}%`;
          }
        }, err => reject(err));
    });
  }

}
