import { ProdutosEditarComponent } from "./pages/produtos-editar/produtos-editar.component";
import { ProdutosCriarComponent } from "./pages/produtos-criar/produtos-criar.component";
import { ProdutosComponent } from "./pages/produtos/produtos.component";
import { CategoriasEditarComponent } from "./pages/categorias-editar/categorias-editar.component";
import { CategoriasCriarComponent } from "./pages/categorias-criar/categorias-criar.component";
import { CategoriasComponent } from "./pages/categorias/categorias.component";
import { IsLoggedInGuard } from "./guards/is-logged-in.guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
// pages
import { HomeComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { UsuariosEditarComponent } from "./pages/usuarios-editar/usuarios-editar.component";
import { UsuariosCriarComponent } from "./pages/usuarios-criar/usuarios-criar.component";
import { UsuariosComponent } from "./pages/usuarios/usuarios.component";
import { ContatoComponent } from "./pages/contato/contato.component";
import { SobreComponent } from "./pages/sobre/sobre.component";
import { BannersEditarComponent } from "./pages/banners-editar/banners-editar.component";
import { BannersCriarComponent } from "./pages/banners-criar/banners-criar.component";
import { BannersComponent } from "./pages/banners/banners.component";
import { DepoimentosEditarComponent } from "./pages/depoimentos-editar/depoimentos-editar.component";
import { DepoimentosCriarComponent } from "./pages/depoimentos-criar/depoimentos-criar.component";
import { DepoimentosComponent } from "./pages/depoimentos/depoimentos.component";
import { FornecedoresEditarComponent } from "./pages/fornecedores-editar/fornecedores-editar.component";
import { FornecedoresCriarComponent } from "./pages/fornecedores-criar/fornecedores-criar.component";
import { FornecedoresComponent } from "./pages/fornecedores/fornecedores.component";
import { NoticiasEditarComponent } from "./pages/noticias-editar/noticias-editar.component";
import { NoticiasCriarComponent } from "./pages/noticias-criar/noticias-criar.component";
import { NoticiasComponent } from "./pages/noticias/noticias.component";
import { SubcategoriasComponent } from "./pages/subcategorias/subcategorias.component";
import { SubcategoriasCriarComponent } from "./pages/subcategorias-criar/subcategorias-criar.component";
import { SubcategoriasEditarComponent } from "./pages/subcategorias-editar/subcategorias-editar.component";

const routes: Routes = [
  { path: "", redirectTo: "/", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "", component: HomeComponent, canActivate: [IsLoggedInGuard] },
  {
    path: "contato",
    component: ContatoComponent,
    canActivate: [IsLoggedInGuard],
  },
  { path: "sobre", component: SobreComponent, canActivate: [IsLoggedInGuard] },
  {
    path: "banners",
    component: BannersComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "banners/adicionar",
    component: BannersCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "banners/editar/:id",
    component: BannersEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "categorias",
    component: CategoriasComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "categorias/adicionar",
    component: CategoriasCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "categorias/editar/:id",
    component: CategoriasEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "subcategorias",
    component: SubcategoriasComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "subcategorias/adicionar",
    component: SubcategoriasCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "subcategorias/editar/:id",
    component: SubcategoriasEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "produtos",
    component: ProdutosComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "produtos/adicionar",
    component: ProdutosCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "produtos/editar/:id",
    component: ProdutosEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "noticias",
    component: NoticiasComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "noticias/adicionar",
    component: NoticiasCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "noticias/editar/:id",
    component: NoticiasEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "fornecedores",
    component: FornecedoresComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "fornecedores/adicionar",
    component: FornecedoresCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "fornecedores/editar/:id",
    component: FornecedoresEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "depoimentos",
    component: DepoimentosComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "depoimentos/adicionar",
    component: DepoimentosCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "depoimentos/editar/:id",
    component: DepoimentosEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "usuarios",
    component: UsuariosComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "usuarios/adicionar",
    component: UsuariosCriarComponent,
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "usuarios/editar/:id",
    component: UsuariosEditarComponent,
    canActivate: [IsLoggedInGuard],
  },
  { path: "**", redirectTo: "/not-found" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
