import { CategoriasComponent } from "./pages/categorias/categorias.component";
import { CategoriasEditarComponent } from "./pages/categorias-editar/categorias-editar.component";
import { CategoriasCriarComponent } from "./pages/categorias-criar/categorias-criar.component";
import { BannersEditarComponent } from "./pages/banners-editar/banners-editar.component";
import { BannersComponent } from "./pages/banners/banners.component";
import { NgModule } from "@angular/core";
// Material
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTableModule } from "@angular/material/table";
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from "@angular/material/paginator";
import { getBrazilianPaginatorIntl } from "./brazilian-paginator-intl";
import { MatSortModule } from "@angular/material/sort";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTreeModule } from "@angular/material/tree";
// imports
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { NgxMaskModule } from "ngx-mask";
import { NgxCurrencyModule } from "ngx-currency";
import { NgxDropzoneModule } from "ngx-dropzone";
import { NgxSummernoteModule } from "ngx-summernote";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
// components
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { HeaderComponent } from "./components/header/header.component";
import { ModalResetPasswordComponent } from "./components/modal-reset-password/modal-reset-password.component";
// pages
import { HomeComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { UsuariosEditarComponent } from "./pages/usuarios-editar/usuarios-editar.component";
import { UsuariosCriarComponent } from "./pages/usuarios-criar/usuarios-criar.component";
import { UsuariosComponent } from "./pages/usuarios/usuarios.component";
import { ContatoComponent } from "./pages/contato/contato.component";
import { SobreComponent } from "./pages/sobre/sobre.component";
import { DepoimentosEditarComponent } from "./pages/depoimentos-editar/depoimentos-editar.component";
import { DepoimentosCriarComponent } from "./pages/depoimentos-criar/depoimentos-criar.component";
import { DepoimentosComponent } from "./pages/depoimentos/depoimentos.component";
import { FornecedoresEditarComponent } from "./pages/fornecedores-editar/fornecedores-editar.component";
import { FornecedoresCriarComponent } from "./pages/fornecedores-criar/fornecedores-criar.component";
import { FornecedoresComponent } from "./pages/fornecedores/fornecedores.component";
import { NoticiasEditarComponent } from "./pages/noticias-editar/noticias-editar.component";
import { NoticiasCriarComponent } from "./pages/noticias-criar/noticias-criar.component";
import { NoticiasComponent } from "./pages/noticias/noticias.component";
import { ProdutosComponent } from "./pages/produtos/produtos.component";
import { ProdutosCriarComponent } from "./pages/produtos-criar/produtos-criar.component";
import { ProdutosEditarComponent } from "./pages/produtos-editar/produtos-editar.component";

// locale
import { LOCALE_ID } from "@angular/core";
import localePt from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
import { BannersCriarComponent } from "./pages/banners-criar/banners-criar.component";
import { FilterPipe } from "./pipes/filter.pipe";
import { UploadSectionComponent } from "./components/upload-section/upload-section.component";
import { UploadDialogComponent } from "./components/upload-dialog/upload-dialog.component";
import { SubcategoriasComponent } from "./pages/subcategorias/subcategorias.component";
import { SubcategoriasCriarComponent } from "./pages/subcategorias-criar/subcategorias-criar.component";
import { SubcategoriasEditarComponent } from "./pages/subcategorias-editar/subcategorias-editar.component";
import { ProdutoCategoriasComponent } from './pages/produto-categorias/produto-categorias.component';
registerLocaleData(localePt);

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  allowZero: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
  nullable: true,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ModalResetPasswordComponent,
    DialogComponent,
    LoadingComponent,
    // pages
    HomeComponent,
    LoginComponent,
    UsuariosComponent,
    UsuariosCriarComponent,
    UsuariosEditarComponent,
    ContatoComponent,
    SobreComponent,
    BannersComponent,
    BannersCriarComponent,
    BannersEditarComponent,
    DepoimentosComponent,
    DepoimentosCriarComponent,
    DepoimentosEditarComponent,
    FornecedoresComponent,
    FornecedoresCriarComponent,
    FornecedoresEditarComponent,
    NoticiasComponent,
    NoticiasCriarComponent,
    NoticiasEditarComponent,
    CategoriasComponent,
    CategoriasCriarComponent,
    CategoriasEditarComponent,
    ProdutosComponent,
    ProdutosCriarComponent,
    ProdutosEditarComponent,
    FilterPipe,
    UploadSectionComponent,
    UploadDialogComponent,
    SubcategoriasComponent,
    SubcategoriasCriarComponent,
    SubcategoriasEditarComponent,
    ProdutoCategoriasComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxSummernoteModule,
    NgxDropzoneModule,
    NgxMatSelectSearchModule,
    // Material
    MatInputModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatTreeModule,
  ],
  entryComponents: [
    DialogComponent,
    ModalResetPasswordComponent,
    UploadDialogComponent,
  ],
  providers: [
    { provide: MatPaginatorIntl, useValue: getBrazilianPaginatorIntl() },
    { provide: MAT_DATE_LOCALE, useValue: "pt-BR" },
    { provide: LOCALE_ID, useValue: "pt-BR" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
