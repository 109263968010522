export class Categoria {
  id: number;
  imagem: string = "";
  nome: string = "";
  situacao: string = "A";
  categoria: string = "";
  slug: string = "";
  mostraTitulo: string = "S";
  [x: string]: any;

  constructor() {
    Object.assign(this);
  }
}
