import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GlobalService } from "src/app/services/global.service";
import { HelperService } from "src/app/services/helper.service";
import { UploadDialogComponent } from "../upload-dialog/upload-dialog.component";

@Component({
  selector: "upload-section",
  templateUrl: "./upload-section.component.html",
  styleUrls: ["./upload-section.component.scss"],
})
export class UploadSectionComponent implements OnInit {
  @Input("label") label?: string;
  @Input("src") src: string = "assets/image-placeholder.png";
  @Output("onUploadImage") onUploadImage = new EventEmitter<string>();

  constructor(
    public dialog: MatDialog,
    public global: GlobalService,
    public helper: HelperService
  ) {}

  ngOnInit(): void {}

  openUploadDialog() {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: "640px",
      data: {
        imagePath: this.src,
        fileName: this.label,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.onUploadImage.next(result);
    });
  }
}
