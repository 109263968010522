import { Fornecedor } from "../../models/fornecedor.model";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FornecedorService {
  constructor(public api: ApiService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    filtro: string = '%'
  ): Observable<FornecedoresApi> {
    const params = {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
    };
    return this.api.get("/fornecedor/buscar", params);
  }

  getById(id): Observable<any> {
    return this.api.get(`/fornecedor/buscar`, { id: id });
  }

  post(fornecedor: Fornecedor): Observable<any> {
    return this.api.post("/fornecedor/inserir", fornecedor);
  }

  patch(fornecedor: Fornecedor): Observable<any> {
    return this.api.post("/fornecedor/alterar", fornecedor);
  }

  delete(fornecedor: Fornecedor): Observable<any> {
    return this.api.post("/fornecedor/deletar", fornecedor);
  }

  deleteSelected(fornecedores: Fornecedor[]): Observable<any> {
    return this.api.post("/fornecedor/deletarLista", fornecedores);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }
}

export interface FornecedoresApi {
  fornecedores: Fornecedor[];
  numeroPaginas: number;
}
