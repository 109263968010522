import { GlobalService } from "./../../services/global.service";
import { AuthService } from "./../../services/auth.service";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { Usuario } from "./../../models/usuario.model";
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

@Component({
  selector: "app-modal-reset-password",
  templateUrl: "./modal-reset-password.component.html",
  styleUrls: ["./modal-reset-password.component.scss"],
})
export class ModalResetPasswordComponent {
  user: Usuario = new Usuario();
  loading: boolean = false;

  constructor(
    public helper: HelperService,
    public loadingService: LoadingService,
    public auth: AuthService,
    public http: HttpClient,
    public global: GlobalService,
    public router: Router,
    public dialogRef: MatDialogRef<ModalResetPasswordComponent>
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  recuperarSenha() {
    if (this.user.senha !== this.user.re_senha) {
      this.helper.openSnackBar("As senhas não coincidem.");
      return;
    }

    this.dialogRef.close(true);
    this.loadingService.present("Recuperando a conta...");
    this.auth.user.senha = this.user.senha;
    this.http
      .post(this.global.apiUrl + "/usuario/alterarSenha", this.auth.user)
      .subscribe(
        (res) => {
          this.auth.login(this.auth.user).subscribe((res: Usuario) => {
            if (!res.autenticacao.sucesso) {
              this.helper.openSnackBar(res.autenticacao.erro);
              this.loadingService.dismiss();
              return;
            }

            // autenticacao
            this.auth.setToken(btoa(this.user.email + ":" + this.user.senha));
            this.router.navigate(["/"]);
            this.loadingService.dismiss();
            this.auth.setUser(res);
          });
        },
        (e) => {
          this.helper.openSnackBar(e.error);
          this.loadingService.dismiss();
        }
      );
  }
}
