import { Injectable } from "@angular/core";
import { ProdutoSubcategoria } from "src/app/models/produto-subcategoria.model";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class ProdutoSubcategoriaService {
  constructor(public api: ApiService) {}

  get(idProduto: number) {
    return this.api.get("/produtoSubcategoria/buscar", {
      idProduto: idProduto,
    });
  }

  post(produtoSubcategoria: ProdutoSubcategoria) {
    return this.api.post("/produtoSubcategoria/inserir", produtoSubcategoria);
  }

  delete(produtoSubcategoria: ProdutoSubcategoria) {
    return this.api.post("/produtoSubcategoria/deletar", produtoSubcategoria);
  }
}
