import { CategoriaService } from "./../../services/class/categoria.service";
import { Categoria } from "./../../models/categoria.model";
// Default
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { HttpProgressEvent } from "@angular/common/http";
import { MatSlideToggleChange } from "@angular/material";
import { Router } from "@angular/router";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-categorias-criar",
  templateUrl: "./categorias-criar.component.html",
  styleUrls: ["./categorias-criar.component.scss"],
})
export class CategoriasCriarComponent implements OnInit {
  data: Categoria = new Categoria();

  constructor(
    public categoriaService: CategoriaService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  submit(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    this.loadingService.present("Inserindo categoria...");
    this.data.slug = this.helper.convertToSlug(this.data.nome);

    this.categoriaService.post(this.data).subscribe(
      (res: any) => {
        this.router.navigate(["/categorias"]).then(() => {
          this.helper.openSnackBar("Categoria inserida com sucesso.");
          this.loadingService.dismiss();
        });
      },
      (e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      }
    );
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.situacao = "A") : (this.data.situacao = "I");
  }

  onMostraTituloChange(event: MatSlideToggleChange) {
    event.checked
      ? (this.data.mostraTitulo = "S")
      : (this.data.mostraTitulo = "N");
  }
}
