import { Categoria } from "./../../models/categoria.model";
import { Observable } from "rxjs";
import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CategoriaService {
  constructor(public api: ApiService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    filtro: string = '%'
  ): Observable<CategoriasApi> {
    const params = {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
    };
    return this.api.get("/categoria/buscar", params);
  }

  getById(id): Observable<any> {
    return this.api.get(`/categoria/buscar`, { id: id });
  }

  post(categoria: Categoria): Observable<any> {
    return this.api.post("/categoria/inserir", categoria);
  }

  patch(categoria: Categoria): Observable<any> {
    return this.api.post("/categoria/alterar", categoria);
  }

  delete(categoria: Categoria): Observable<any> {
    return this.api.post("/categoria/deletar", categoria);
  }

  deleteSelected(categorias: Categoria[]): Observable<any> {
    return this.api.post("/categoria/deletarLista", categorias);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  compareFn(v1: Categoria, v2: Categoria): boolean {
    return v1 && v2 ? v1.id === v2.id : v1 === v2;
  }
}

export interface CategoriasApi {
  categorias: Categoria[];
  numeroPaginas: number;
}
