import { Produto } from "./../../models/produto.model";
import { ApiService } from "./../api.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ProdutoService {
  constructor(public api: ApiService) {}

  get(
    numeroPagina: number,
    registroPorPagina: number,
    ordenacao: string,
    sentidoOrdenacao: string,
    filtro: string = "%"
  ): Observable<ProdutosApi> {
    const params = {
      filtro: filtro,
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
      ordenacao: ordenacao,
      sentidoOrdenacao: sentidoOrdenacao,
    };
    return this.api.get("/produto/buscar", params);
  }

  getById(id): Observable<any> {
    return this.api.get("/produto/buscar", { id: id });
  }

  post(produto: Produto): Observable<any> {
    return this.api.post("/produto/inserir", produto);
  }

  patch(produto: Produto): Observable<any> {
    return this.api.post("/produto/alterar", produto);
  }

  delete(produto: Produto): Observable<any> {
    return this.api.post("/produto/deletar", produto);
  }

  deleteSelected(produtos: Produto[]): Observable<any> {
    return this.api.post("/produto/deletarLista", produtos);
  }

  postFile(file: File, url: string, fileName: string) {
    return this.api.postFile(file, url, fileName);
  }

  postImages(files: File[], url: string, body: Object) {
    return this.api.postAsFormData(url, body, files);
  }

  deleteImage(idImagem: number): Observable<any> {
    return this.api.post(`/produto/deletarImagemGaleria?idImagem=${idImagem}`);
  }
}

export interface ProdutosApi {
  produtos: Produto[];
  numeroPaginas: number;
}
