import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { GlobalService } from "./global.service";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  url = `${this.global.apiUrl}/imagem/upload`;

  constructor(
    public global: GlobalService,
    public auth: AuthService,
    public http: HttpClient
  ) {}

  submit(files) {
    console.log(files);
  }

  upload(fileToUpload: File, fileName: string) {
    const formData: FormData = new FormData();
    formData.append(fileName, fileToUpload, fileToUpload.name);

    const options = {
      method: "POST",
      httpParams: new HttpParams(),
    };

    const req = new HttpRequest(options.method, this.url, formData, {
      params: options.httpParams,
      reportProgress: true,
      headers: this.auth.getAuthorizationHeader(),
    });

    return this.http.request(req).pipe(
      map((r) => r),
      catchError((e) => this.handleError(e))
    );
  }

  uploadMultipleFiles(files: File[], url: string) {
    const formData: FormData = new FormData();

    for (var i = 0; i < files.length; i++) {
      formData.append(`file${i}[]`, files[i], files[i].name);
    }

    const options = {
      method: "POST",
      httpParams: new HttpParams(),
    };

    const req = new HttpRequest(
      options.method,
      this.global.apiUrl + url,
      formData,
      {
        params: options.httpParams,
        reportProgress: true,
        headers: this.auth.getAuthorizationHeader(),
      }
    );

    return this.http.request(req).pipe(
      map((r) => r),
      catchError((e) => this.handleError(e))
    );
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
