import { BannerService } from "./../../services/class/banner.service";
// default
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { GlobalService } from "./../../services/global.service";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { merge, of as observableOf, Subscription } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";
import { SelectionModel } from "@angular/cdk/collections";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { Banner } from "src/app/models/banner.model";

@Component({
  selector: "app-banners",
  templateUrl: "./banners.component.html",
  styleUrls: ["./banners.component.scss"],
})
export class BannersComponent implements OnInit {
  displayedColumns: string[] = [
    "select",
    "imagem",
    "1",
    "2",
    "3",
    "4",
    "actions",
  ];
  // imagem, titulo, descricao, link, situacao
  data: Banner[] = [];

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  search: string;
  filterSubscription: Subscription;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  selection = new SelectionModel<Banner>(true, []);

  constructor(
    public global: GlobalService,
    public bannerService: BannerService,
    public dialog: MatDialog,
    public helper: HelperService,
    public loadingService: LoadingService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.buscarBanners();
  }

  buscarBanners() {
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.bannerService.get(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction.toLocaleUpperCase()
          );
        }),
        map((data) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.selection.clear();
          this.resultsLength = data.numeroPaginas * this.paginator.pageSize;
          return data.banners;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          this.selection.clear();
          return observableOf([]);
        })
      )
      .subscribe((data) => (this.data = data));
  }

  deletarBanner(banner: Banner) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir banner",
        description:
          "Você realmente quer excluir esse banner? Esse processo não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Excluindo banner");
        this.bannerService.delete(banner).subscribe(
          (res: any) => {
            this.helper.openSnackBar("Banner removido com sucesso.");
            this.loadingService.dismiss();
            this.buscarBanners();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  deletarBanners() {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir banners",
        description:
          "Você realmente quer excluir esses banners? Esse processo não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.bannerService.deleteSelected(this.selection.selected).subscribe(
          (res: any) => {
            this.loadingService.present("Excluindo banners");
            this.helper.openSnackBar("Banners removidos com sucesso.");
            this.loadingService.dismiss();
            this.buscarBanners();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }

  filter(e) {
    if (this.paginator.pageIndex > 1) {
      this.paginator.pageIndex = 0;
    }
    if (e) {
      if (this.filterSubscription && !this.filterSubscription.closed) {
        this.filterSubscription.unsubscribe();
      }
      this.filterSubscription = this.bannerService
        .get(
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
          this.sort.active,
          this.sort.direction.toLocaleUpperCase(),
          e.toLocaleLowerCase()
        )
        .subscribe((res: any) => {
          this.data =
            this.paginator.pageIndex == 0
              ? res.banners
              : this.data.concat(res.banners);
        });
    } else {
      this.buscarBanners();
    }
  }

  clearFilter() {
    this.search = "";
    this.buscarBanners();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: Banner): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }
}
