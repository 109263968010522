import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { Categoria } from "src/app/models/categoria.model";
import { ProdutoSubcategoria } from "src/app/models/produto-subcategoria.model";
import { Subcategoria } from "src/app/models/subcategoria.model";
import { CategoriaService } from "src/app/services/class/categoria.service";
import { ProdutoSubcategoriaService } from "src/app/services/class/produto-subcategoria.service";
import { SubcategoriaService } from "src/app/services/class/subcategoria.service";
import { HelperService } from "src/app/services/helper.service";
import { LoadingService } from "src/app/services/loading.service";

@Component({
  selector: "app-produto-categorias",
  templateUrl: "./produto-categorias.component.html",
  styleUrls: ["./produto-categorias.component.scss"],
})
export class ProdutoCategoriasComponent implements OnInit {
  @Input("idProduto") idProduto: number;

  categorias: Categoria[] = [];
  subcategorias: Subcategoria[] = [];
  searchOptions: string = "";
  isNewCategoria: boolean = false;
  idCategoria: number = null;
  newSubcategoria = new Subcategoria();

  produtosSubcategoria: ProdutoSubcategoria[] = [];

  constructor(
    public categoriaService: CategoriaService,
    public subcategoriaService: SubcategoriaService,
    public produtoSubcategoriaService: ProdutoSubcategoriaService,
    public loadingService: LoadingService,
    public dialog: MatDialog,
    public helper: HelperService
  ) {}

  ngOnInit() {
    this.buscar();
  }

  buscar() {
    this.produtoSubcategoriaService.get(this.idProduto).subscribe((res) => {
      this.produtosSubcategoria = res;
      this.buscarCategorias();
    });
  }

  buscarCategorias() {
    this.categoriaService.get(-99, -99, "1", "asc").subscribe((res) => {
      this.categorias = res.categorias;
      // this.categorias = res.categorias.map((item) => {
      //   const disabled = this._subcategorias.some(
      //     (pc) => pc.
      //   );
      //   console.log(disabled);
      //   item.disabled = disabled;
      //   return item;
      // });
    });
  }

  onChangeCategoria(ev) {
    this.subcategorias = [];
    if (!ev) return;
    this.subcategoriaService
      .get(-99, -99, "1", "asc", ev)
      .subscribe((res) => (this.subcategorias = res.subcategorias));
  }

  addNewSubcategoria() {
    if (!this.newSubcategoria.id) {
      return;
    }
    this.loadingService.present("Adicionando...");
    const produtoSubcategoria = {
      idProduto: this.idProduto,
      subcategoria: this.newSubcategoria,
    } as ProdutoSubcategoria;
    this.produtoSubcategoriaService.post(produtoSubcategoria).subscribe(
      (res) => {
        this.buscar();
        this.loadingService.dismiss();
      },
      (e) => this.loadingService.dismiss()
    );
  }

  deletar(data: ProdutoSubcategoria) {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: {
        title: "Excluir subcategoria",
        description:
          "Você realmente quer excluir essa subcategoria? Esse processo não pode ser desfeito.",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loadingService.present("Excluindo subcategoria");
        this.produtoSubcategoriaService.delete(data).subscribe(
          (res: any) => {
            this.helper.openSnackBar("Produto removido com sucesso.");
            this.loadingService.dismiss();
            this.buscar();
          },
          (e) => this.loadingService.dismiss()
        );
      }
    });
  }
}
