class Autenticacao {
  sucesso: boolean;
  erro: string;
}

export class Usuario {
  id: number;
  codigo: string = "";
  nome: string;
  sobrenome: string;
  email: string;
  senha: string;
  tipo: string = "N";
  situacao: string = "A";
  solicitaAlteracao: string = "";
  codigoRecuperacao: string = "";
  autenticacao: Autenticacao = new Autenticacao();
  [x: string]: any;

  constructor(obj?) {}
}
