import { Categoria } from "src/app/models/categoria.model";
import {
  CategoriaService,
  CategoriasApi,
} from "./../../services/class/categoria.service";
import { Subscription } from "rxjs";
import { ProdutoService } from "./../../services/class/produto.service";
import { Produto } from "./../../models/produto.model";
// Default
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { HttpProgressEvent } from "@angular/common/http";
import { MatSlideToggleChange } from "@angular/material";
import { Router } from "@angular/router";
import { LoadingService } from "./../../services/loading.service";
import { HelperService } from "./../../services/helper.service";
import { NgForm } from "@angular/forms";
import { Subcategoria } from "src/app/models/subcategoria.model";
import { SubcategoriaService } from "src/app/services/class/subcategoria.service";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-produtos-criar",
  templateUrl: "./produtos-criar.component.html",
  styleUrls: ["./produtos-criar.component.scss"],
})
export class ProdutosCriarComponent implements OnInit {
  data: Produto = new Produto();

  categorias: Categoria[] = [];
  subcategorias: Subcategoria[] = [];
  searchOptions: string = "";

  files: File[] = [];
  progress: number = 0;

  constructor(
    public produtoService: ProdutoService,
    public helper: HelperService,
    public loadingService: LoadingService,
    public router: Router,
    public auth: AuthService
  ) {}

  ngOnInit(): void {}

  submit(f: NgForm) {
    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      this.helper.openSnackBar("Preencha os campos requiridos, por favor!");
      return;
    }

    this.loadingService.present("Inserindo produto...");
    this.data.slug = this.helper.convertToSlug(this.data.nome);
    this.data.usuario = this.auth.user

    this.produtoService.post(this.data).subscribe(
      (res: any) => {
        if (this.files.length) {
          this.submitGaleria(
            this.files,
            `/produto/carregarImagens?idProduto=${res}`
          ).then(() => {
            this.router.navigate(["/produtos/editar", res]).then(() => {
              this.helper.openSnackBar("Produto inserido com sucesso.");
              this.loadingService.dismiss();
            });
          });
        } else {
          this.router.navigate(["/produtos"]).then(() => {
            this.helper.openSnackBar("Produto inserido com sucesso.");
            this.loadingService.dismiss();
          });
        }
      },
      (e) => {
        this.helper.openSnackBar(e.error);
        this.loadingService.dismiss();
      }
    );
  }

  onNovoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.novo = "S") : (this.data.novo = "N");
  }

  onPromocaoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.promocao = "S") : (this.data.promocao = "N");
  }

  onSituacaoChange(event: MatSlideToggleChange) {
    event.checked ? (this.data.situacao = "A") : (this.data.situacao = "I");
  }

  submitGaleria(files: File[], url: string) {
    if (!files.length) {
      return;
    }
    console.log(files);
    return new Promise((resolve, reject) => {
      this.produtoService.postImages(files, url, {}).subscribe(
        (event: HttpProgressEvent | any) => {
          if (event.type === 4) {
            this.progress = 0;
            resolve(event);
          } else {
            this.progress = Math.round((event.loaded / event.total) * 100);
            if (isNaN(this.progress)) {
              this.progress = 100;
            }
          }
        },
        (err) => reject(err)
      );
    });
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
